<template>
	<svg
		:width="size"
		:height="size"
		:viewBox="[0, 0, size, size].join(' ')"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle
			:cx="size/2"
			:cy="size/2"
			:r="size/2"
			:fill="color"
		/>
		<path
			:d="path.join(' ')"
			:fill="textColor"
		/>
	</svg>
</template>
<script>
export default {
	name: 'TimelineCheck',
	props: {
		color: {
			type: String,
			default: 'black'
		},
		textColor: {
			type: String,
			default: 'white'
		},
		// TODO: Adjust size of check icon path
		size: {
			type: Number,
			default: 25
		}
	},
	data () {
		return {
			path: [
				'M11.2258 18.6155C10.6499 18.6155 10.0975 18.3781',
				'9.69701 17.9561L5.59212 13.6314C4.78024',
				'12.776 4.80651 11.4158 5.65092 10.5934C6.49527',
				'9.77091 7.83797 9.79746 8.64991 10.6529L11.1214',
				'13.2568L15.6868 7.76477C16.4411 6.85697 17.7793',
				'6.74078 18.6753 7.50519C19.5713 8.26954',
				'19.6861 9.62496 18.9315 10.5326L12.8483 17.8507C12.461',
				'18.3167 11.8968 18.5942 11.2958 18.6142C11.2725',
				'18.6151 11.2492 18.6155 11.2258 18.6155Z'
			]
		}
	}
}
</script>